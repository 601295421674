<template>
  <v-row>
    <v-col
      sm="3"
      cols="6"
    >
      <v-card
        class="mt-0 mt-0 d-flex justify-center align-center"
        style="background-color: rgba(45, 173, 237, 1); color: white; width: 100%; height: 100%; gap:5%; padding: 5%; text-align: right; border-radius: 11px;"
        elevation="5"
      >
        <v-icon
          color="white"
          :size="iconSize"
        >
          mdi-account-cowboy-hat
        </v-icon>
        <div>
          <div :style="textSize">
            {{ dashboard.produtores_atendidos ? dashboard.produtores_atendidos : 0 }}/ {{ dashboard.produtores }}
          </div>
          <div :style="textSizeDown">
            Atendidos
          </div>
        </div>
      </v-card>
    </v-col>
    <v-col
      sm="3"
      cols="6"
    >
      <v-card
        class="mt-0 mt-0 d-flex justify-center align-center"
        style="background-color: rgba(45, 173, 237, 1); color: white; width: 100%; height: 100%; gap:5%; padding: 5%; text-align: right; border-radius: 11px"
        elevation="5"
      >
        <v-icon
          color="white"
          :size="iconSize"
        >
          mdi-chart-pie
        </v-icon>
        <div>
          <div :style="textSize">
            {{ dashboard.total_pedidos ? dashboard.total_pedidos : 0 }}
          </div>
          <div :style="textSizeDown">
            Pedidos {{ calculoVolume }}
          </div>
        </div>
      </v-card>
    </v-col>
    <v-col
      sm="6"
      cols="12"
    >
      <v-card
        class="mt-0 mt-0 d-flex justify-center align-center"
        style="background-color: rgba(45, 173, 237, 1); color: white; width: 100%; height: 100%; gap:5%; padding: 5%; text-align: right; border-radius: 11px"
        elevation="5"
      >
        <v-icon
          color="white"
          :size="iconSize"
        >
          mdi-chart-areaspline
        </v-icon>
        <div>
          <div :style="textSize">
            {{ dashboard.volume ? currencyFormatter(dashboard.volume, 0) : 0 }} L
          </div>
          <div :style="textSizeDown">
            Total {{ calculoVolume }}
          </div>
        </div>
        <div>
          <div :style="textSize">
            {{ dashboard.media_diaria ? currencyFormatter(dashboard.media_diaria, 0) : 0 }} L
          </div>
          <div :style="textSizeDown">
            Média diária
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mapState } from 'vuex'
  // import DashboardStore, { BOOTSTRAP } from '@/store/modules/dashboard'
  import { currencyFormatter } from '../../utils/formatter'

  export default {
    name: 'TecnicoInfos',
    data () {
      return {
        currencyFormatter,
        dialog: false,
      }
    },
    computed: {
      ...mapState('dashboard', ['ready', 'dashboard']),
      calculoVolume () {
        const dataAtual = new Date()
        const mesAtual = dataAtual.getMonth()
        const anoAtual = dataAtual.getFullYear().toString().slice(-2)
        const mesesAbreviados = [
          'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
          'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
        const data = `${mesesAbreviados[mesAtual]}/${anoAtual}`
        return data
      },
      textSize () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'font-weight: 700; font-size: 4vw;'
          case 'sm': return 'font-weight: 700; font-size: 3vw'
          case 'md': return 'font-weight: 700; font-size: 2vw'
          case 'lg': return 'font-weight: 700; font-size: 2vw'
          case 'xl': return 'font-weight: 700; font-size: 2vw'
          case 'xxl': return 'font-weight: 700; font-size: 2vw'
          default: return 'color: white;'
        }
      },
      textSizeDown () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'font-size: 3vw'
          case 'sm': return 'font-size: 2vw'
          case 'md': return 'font-size: 16px'
          case 'lg': return 'font-size: 18px'
          case 'xl': return 'font-size: 18px'
          case 'xxl': return 'font-size: 18px'
          default: return 'font-size: 16px'
        }
      },
      iconSize () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '10vw'
          case 'sm': return '9vw'
          case 'md': return '5vh'
          case 'lg': return '7vh'
          case 'xl': return '7vh'
          case 'xxl': return '7vh'
          default: return '50'
        }
      },
    },
  }
</script>
