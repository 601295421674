<template>
  <div>
    <base-loading v-if="!ready" />
    <v-container
      v-else
      :style="$vuetify.breakpoint.lgAndDown ? 'width: 100%;' : 'width: 75%;'"
      fluid
    >
      <v-container>
        <v-row>
          <dashboard-carrosel />
        </v-row>

        <tecnico-infos />
      </v-container>
      <v-container style="width: 100%; margin-bottom: 60px;">
        <v-row>
          <div
            style="font-size: 32px; font-weight: 700; color: rgba(24, 59, 148, 1); margin-left: 20px; margin-top: 10px;"
          >
            Tem no Clube
          </div>
          <v-divider
            class="custom-divider"
            height="10px"
            max-height="5px"
            style="background-color:  rgba(24, 59, 148, 1); height: 3px; max-height: 50px; margin-top: 35px; margin-left: 30px;"
          />
        </v-row>

        <v-row>
          <v-col
            md="4"
            sm="6"
            cols="12"
          >
            <a
              class="image-container"
              @click="$router.push({ path: 'cdl/volumes/grafico' }).catch(() => {})"
            >
              <p
                class="container-text"
                :style="titleSize"
              >
                Volume
              </p>
              <v-img
                src="@/assets/menu-tecnico/C1.png"
              />
            </a>
          </v-col>
          <v-col
            md="4"
            sm="6"
            cols="12"
          >
            <a
              class="image-container"
              @click="$router.push({ path: 'cdl/qualidades/grafico' }).catch(() => {})"
            >
              <p
                class="container-text"
                :style="titleSize"
              >
                Qualidade
              </p>
              <v-img
                src="@/assets/menu-tecnico/C2.png"
              />
            </a>
          </v-col>
          <v-col
            md="4"
            sm="6"
            cols="12"
          >
            <a
              class="image-container"
              @click="$router.push({ path: 'loja' }).catch(() => {})"
            >
              <p
                :style="titleSize"
                class="container-text"
              >
                Promoções
              </p>
              <v-img
                src="@/assets/menu-tecnico/C3.png"
              />
            </a>
          </v-col>
          <v-col
            md="4"
            sm="6"
            cols="12"
          >
            <a
              class="image-container"
              @click="$router.push({ path: 'cdv/pedidos' }).catch(() => {})"
            >
              <p
                :style="titleSize"
                class="container-text"
              >
                Pedidos
              </p>
              <v-img
                src="@/assets/menu-tecnico/C4.png"
              />
            </a>
          </v-col>
          <v-col
            md="4"
            sm="6"
            cols="12"
          >
            <a
              class="image-container"
              @click="$router.push({ path: 'cdv/cotacoes' }).catch(() => {})"
            >
              <p
                :style="titleSize"
                class="container-text"
              >
                Cotações
              </p>
              <v-img
                src="@/assets/menu-tecnico/C5.png"
              />
            </a>
          </v-col>
          <v-col
            md="4"
            sm="6"
            cols="12"
          >
            <a
              href="http://www.clubedoprodutor.com.br"
              target="_blank"
              class="image-container"
            >
              <p
                :style="titleSize"
                class="container-text"
              >
                Academia
              </p>
              <v-img
                src="@/assets/menu-tecnico/C6.png"
              />
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import DashboardCarrosel from '../../components/Dashboards/DashboardCarrosel.vue'
  import DashboardStore, { BOOTSTRAP } from '@/store/modules/dashboard'
  import TecnicoInfos from '../../components/Dashboards/TecnicoInfos.vue'

  export default {
    name: 'DashboardTecnico',
    components: {
      DashboardCarrosel,
      TecnicoInfos,
    },
    data () {
      return {
        dialog: false,
      }
    },
    computed: {
      ...mapState('dashboard', ['ready', 'dashboard']),
      profile () {
        const { avatar, nome, description, role } = this.$user.get()
        return {
          avatar,
          nome,
          description,
          role,
        }
      },
      titleSize () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'font-size: 32px; font-weight: 700; width: 180px'
          case 'sm': return 'font-size: 28px; font-weight: 700; width: 330px'
          case 'md': return 'font-size: 22px; font-weight: 700; width: 300px'
          case 'lg': return 'font-size: 28px; font-weight: 700; width: 380px'
          case 'xl': return 'font-size: 28px; font-weight: 700; width: 380px'
          case 'xxl': return 'font-size: 28px; font-weight: 700; width: 380px'
          default: return 'color: white;'
        }
      },
      textSize () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'font-weight: 700; font-size: 4vw;'
          case 'sm': return 'font-weight: 700; font-size: 3vw'
          case 'md': return 'font-weight: 700; font-size: 2vw'
          case 'lg': return 'font-weight: 700; font-size: 2vw'
          case 'xl': return 'font-weight: 700; font-size: 2vw'
          case 'xxl': return 'font-weight: 700; font-size: 2vw'
          default: return 'color: white;'
        }
      },
      textSizeDown () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'font-size: 3vw'
          case 'sm': return 'font-size: 2vw'
          case 'md': return 'font-size: 16px'
          case 'lg': return 'font-size: 18px'
          case 'xl': return 'font-size: 18px'
          case 'xxl': return 'font-size: 18px'
          default: return 'font-size: 16px'
        }
      },
      iconSize () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '10vw'
          case 'sm': return '9vw'
          case 'md': return '5vh'
          case 'lg': return '7vh'
          case 'xl': return '7vh'
          case 'xxl': return '7vh'
          default: return '50'
        }
      },
    },
    created () {
      if (!this.$store.hasModule('dashboard')) { this.$store.registerModule('dashboard', DashboardStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('dashboard', [BOOTSTRAP]),
    },
  }
</script>

<style lang="scss">
  .image-container {
    position: relative;
    transition: 0.3s;
    display: block;
  }

  .image-container:hover {
    transform: rotate(5deg)
  }

  .container-text {
    color: white;
    position: absolute;
    z-index: 1;
    bottom: 10px;
    left: 50px;
    font-weight: 700;
    width: 120px;
  }
</style>
